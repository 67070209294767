@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  ul,
  ol {
    list-style: revert;
  }

  ul {
    @apply txt;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 80.5%;
}

ol {
  list-style: decimal;
  list-style-position: inside;
  padding: 10px;
}

ol a {
  color: #9451ff
}

a {
  text-decoration: underline;
}

body {
  font-family: 'Roboto', sans-serif;
  /* Light mode */
  background: white;
  color: black;

  /* Dark mode */
  /* background: rgb(3, 0, 31); */
  /* color: white; */

  position: relative;
  overflow-x: hidden;
}

.main-container {
  height: calc(100 * var(--vh));
}

.shapes {
  width: 44.8px;
  height: 44.8px;
  color: #9452FF;
  background: linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0);
  background-size: 23.4px 23.4px;
  background-repeat: no-repeat;
  animation: shapes-53h9rpmd 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes shapes-53h9rpmd {
  0% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }

  33% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    width: 67.2px;
    height: 67.2px;
  }

  66% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    width: 67.2px;
    height: 67.2px;
  }

  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

/* Alert */

.alert-popup {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 500;
}

.alert-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #9452FF
}

.alert-text {
  margin: 0 1rem;
  color: white;
  flex: 10;
}

.alert-close {
  width: 1.5rem;
  height: 1.5rem;
}

.alert-close:hover {
  scale: 1.1;
}

/* this class is user to display sidebar */
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Sidebar */

.sidebar {
  /* flex: 3; */
  border-right: 1px solid rgba(100, 100, 100);
  /* width: 20%; */
  /* min-width: 250px; */
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: 100vh;
  height: 100svh;
  overflow-y: hidden;
  /* width: 20%; */
  left: 0%;
  top: 0%;

  /* Light mode */
  background-color: white;
  border-right: 2px solid #9452FF;
  background-color: #9452FF;

  width: 375px;

  /* Dark mode */
  /* background-color: rgb(3, 0, 31); */
  /* border-right: 1px solid rgba(100, 100, 100); */

}

.upper-side-bottom::-webkit-scrollbar {
  background-color: #bd94ff;
}

.upper-side-bottom::-webkit-scrollbar-thumb {
  background-color: white;
}

.logo-container {
  display: flex;
  justify-content: center;
  /* Horizontally center the content */
  padding-left: 1rem;
}

.logo {
  margin-right: 0rem;
}

.logo-text {
  font-size: 2rem;
}

.upper-side {
  border-width: 20%;
  height: 90%;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* overflow: auto; */

  /* Dark mode */
  /* border-bottom: 1px solid rgba(100, 100, 100); */

  /* Light mode */
  background-color: #9452FF;
  border-bottom: 1px solid white;
  width: 100%;
}

.upper-side-top {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

.add-btn-img {
  height: 2rem;
  padding-right: 1rem;
}

/* @media screen and (max-width: 768px) {
    .sidebar {
      width: 70%;
    }
  } */

.new-chat-btn {
  background: #40CFF9;
  border: none;
  color: white;
  padding: 1rem;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  flex-grow: 0;



}


.upper-side-bottom {
  /* display: flex; */
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  flex-grow: 1;
  align-items: flex-start;

}

.upper-side-bottom :first-child {
  margin-top: 0;
}

.query {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background: transparent;
  color: rgba(222, 222, 222, 1);
  padding: 1.5rem;
  width: 100%;
  margin: 1rem 0 auto;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

}

.queryContainer {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;


}

.query-active {
  /* Dark mode */
  background: rgb(54, 51, 81);
  color: white;

  /* Light mode */
  /* background: white; */
  background: #e8dbfb;
  color: black;
}

.query-active .more-icon {
  display: inline-block !important;
}

.chat-link {
  flex: 80;
  @apply text-ellipsis;
  @apply overflow-hidden;
}

.query .more-icon {
  display: none;
}

.query:hover:not(.query-active) {
  background: #542e90;
  opacity: 0.5;
  color: white;
  text-decoration: none;


}

.query:hover .more-icon {
  display: inline-block;
}

.more-icon {
  margin-left: 2rem;
  /* object-fit: cover; */
  height: 1.3rem;
  flex: 20;
  /* position: relative; */
}

.main-chat-container {
  margin-left: 30rem;
}

.lower-side {
  padding: 2rem;
  margin-right: 1rem;

  /* Light mode */
  color: white;
}

.list-items {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  font-size: 1.3rem;
}

.icon-container {
  position: relative;
  flex: 20;
}

.more-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  z-index: 300;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: #000;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
}

.more-option-icon {
  height: 1.3rem;
  margin-right: 1.5rem;
  /* color: rgb(220, 62, 62) */
}

.delete {
  color: rgb(220, 62, 62)
}

.more-option {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 8px 8px;
  cursor: pointer;
}

.more-option:hover {
  background-color: #f1f1f1;
}

.more-option div {
  padding-right: 5rem;
  /* color: rgb(220, 62, 62) */
}

.rename-input {
  color: black;
}

.list-items>img {
  margin: 1rem;
  padding-right: 1rem;
}

.list-items:nth-child(1)>.list-items-img {
  margin-right: 0.5rem;
}

.list-items:nth-child(3)>.list-items-img {
  margin-right: 0.75rem;
}

.chat-footer {
  margin-top: auto;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 70rem;
  /* height: calc(100vh - 70rem); */
  /* padding-left: 30%; */

  @media (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
    /* margin-top: 15%; */
    display: flex;
    justify-content: center;
    /* height: auto; */
  }
}

.input-container {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.input {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  /* width: 68rem; */
  width: 100%;

  /* Dark mode */
  /* padding: 0.5rem; */
  /* background: rgba(28, 30, 58, 1); */

  /* Light mode */
  background: rgb(230, 246, 249);
  border: 3px solid #40CFF9;
  padding: 0.3rem;
}

.input>textarea {
  outline: none;
  font-size: 1.2rem;

  /* Dark mode */
  /* color: white; */
  /* background: rgba(28, 30, 58, 1); */
  /* padding: 1.25rem; */
  /* width: calc(100% - 3rem); */

  /* Light mode */
  color: black;
  background: rgb(230, 246, 249);
}

.input>textarea::placeholder {
  /* Light mode */
  color: #40CFF9;
}

.input>input,
.send {
  background: transparent;
  border: none;

}

.send-btn {
  /* background-color: #40CFF9; */
  background: linear-gradient(to left, #40CFF9, #9452FF);
  padding: 0.5rem 4rem;
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-weight: 500;
  }
}

.stop-btn {
  /* background-color: #40CFF9; */
  background: #9452FF;
  padding: 0.5rem 4rem;
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-weight: 500;
  }
}

.chat-footer>p {
  margin: 2rem 0
}

.txt {
  line-height: 2.4rem;

  /* Dark mode */
  /* color: rgba(222, 222, 222, 1); */

  /* Light mode */
  color: black;
}

.btn {
  cursor: pointer;
}


/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #9452FF;
  border-color: #9452FF transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Login */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5rem; */

  font-weight: 700;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background: linear-gradient(#9452FF, #40CFF9);
  /* overflow-y: hidden; */

}

@media (max-width: 768px) {
  .wrapper {
    background: none;
  }
}

.wrapper form {
  /* margin-top: 50%; */
  width: 100%;
  padding: 2.5rem 3rem;
  background-color: white;
  /* margin: auto; */

  /* Light mode */
  background-color: white;

  /* Dark mode */
  /* background-color: rgb(3, 0, 31); */
}

/* @media (max-width: 768px) {
  .wrapper form {
    margin-top: 10%;
  }
} */



@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
}

.header h1 {
  padding-top: 1rem;

}

.wrapper form .btn {
  background-color: #40CFF9;
  border-color: #40CFF9;
  color: white;
  margin-top: 4.5rem;
  margin-bottom: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Set the width to 100% */
  height: 50px;
  /* Set the desired height */
  border-radius: 3px;
}

.wrapper form .btn:hover {
  transform: translateY(-5px);
  /* Adjust the value as needed */
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

#login-logo {
  width: 45px;
  height: 45px;
  margin-right: 12px;
}

@media (max-width: 768px) {
  #login-logo {
    width: 25%;
    height: auto;
    /* margin-top: 10%; */
    margin-bottom: 10%;
  }
}

#loginTitle {
  text-align: center;
}

.form-group {
  margin-top: 3.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;

  /* Dark mode */
  /* -webkit-text-fill-color: #ffffff; */
  /* box-shadow: inset 0 0 20px 20px #23232329; */

  /* Light mode */
  -webkit-text-fill-color: black;
  box-shadow: inset 0 0 20px 20px #c7c7c729;
}

.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9452FF;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  height: 4.5rem;
  font-size: 1.2rem;
  padding: 0;
  width: 100%;
}



/* .mobile-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid rgba(100, 100, 100);
  border-right: 1px solid rgba(100, 100, 100);
  height: 10vh;
  width: 100%;
  margin-bottom: 20px;
} */


.bars-img-container {
  position: absolute;
  left: 10px;
  padding-top: 0.8rem;

}

.bars-img-container:hover {
  cursor: pointer;
}

.bars-img {
  height: 40px;
  width: 40px;
}

.bars-img:hover {
  height: 45px;
  width: 45px;
}

.close-img-container {
  position: absolute;
  left: 327px;
  padding-top: 0.8rem;

}

.close-img-container:hover {
  cursor: pointer;
}

.close-img {
  height: 22px;
  width: 22px;
}

.close-img:hover {
  height: 25px;
  width: 25px;
}


.login-link {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #9452FF !important;
  font-size: 1rem !important;
}

.login-link:hover {
  color: #40CFF9 !important;
}

/* Chatbot main */

.chat-container {
  /* min-height: calc(100vh - 14rem); */
  /* display: flex;
  flex-direction: column;
  align-items: center; */

  /* min-height: 100vh; */
  /* width: 100%; */
  height: 100vh;
  /* Set the height to the height of the viewport */
  height: 100svh;
  /* Set the height to the height of the small mobile viewport which does not include default url bar. */
  overflow-y: hidden;
  /* Disable vertical scrolling */
  /* width: fit-content; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* horizontally center content */
  align-items: center;
  /* vertically center content */

  margin: auto;
  margin-bottom: 0;
  padding-top: 5rem;

  /* @media (max-width: 768px) {
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: start;
    
  } */
}

.initial-alga {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chats {
  /* flex-grow: 1; */
  /* overflow: hidden; */
  overflow-y: auto;
  scroll-behavior: smooth;
  /* width: 100%; */
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;

  /* max-width: 70rem; */
  height: calc(100vh - 17rem);
  /* padding-left: 30%; */
  padding-bottom: 1rem;
  /* Added so the feedback input won't be cut off under the chat border */

  /* Light mode */
  border: 3px solid #40CFF9;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 70rem;

  @media (max-width: 768px) {
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 2;

    /* Light mode */
    border: 0;
    margin-bottom: 2.3rem;
  }

}

.chat-block {
  margin: 1rem;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  /* text-align: justify; */
  /* white-space: pre-wrap; */

  /* Light mode */
  display: inline-flex;
  align-items: flex-end;
  width: calc(100% - 2rem);

  /* Dark mode */
  /* display: flex; */
  /* align-items: flex-start; */
}

.chat-block table {
  border-collapse: collapse;
  /* Removes the space between borders */
}

.chat-block td,
th {
  /* Applies to both table header and table data cells */
  border: 2px solid #9452ff;
  /* 1px wide, solid, black border */
  padding: 10px;
  /* 10 pixels of padding inside each cell */
}


.chat-block p {
  display: inline-block;
}

.chat {
  /* Light mode */
  border: 1px solid #40CFF9;
  border-radius: 1.5rem;
  border-end-end-radius: 0;
  padding: 1rem 2rem;
  width: 100%;
  margin-left: 0.2rem;

  /* Dark mode */
  /* padding: 2rem 3rem; */
}

.chat-img {
  object-fit: cover;

  /* Light mode */
  background-color: #9452FF;
  border-radius: 2rem;
  padding: 0.3rem;
  width: 3rem;
  margin-right: 0.8rem;

  /* Dark mode */
  /* border-radius: 0.5rem; */
  /* width: 3.5rem; */
  /* margin-right: 2rem; */
}

.bot {
  /* background: rgba(28, 30, 58, 1); */

  /* Dark mode */
  /* background: #9452FF !important; */
  /* border-radius: 0.5rem; */
  /* width: fit-content; */
  /* border-radius: 0.5rem; */

  /* Light mode */
  background: white !important;
  border: 1px solid #9452FF;
  border-radius: 1.5rem;
  border-end-start-radius: 0;
  width: 100%;
}

/* Feedback */

.feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 1rem;
  gap: 0.6rem;
  position: relative;
}

.thumbs-container {
  display: flex;
  gap: 0.5rem;
  align-self: flex-end;
}

.thumb {
  cursor: pointer;
  opacity: 0.5;
}

.thumb:hover {
  scale: 1.1;
  opacity: 1;
}

.thumb-clicked {
  opacity: 1;
}

.feedback-input-container {
  width: 20rem;
  /* position: absolute; */
  top: 2rem;
  align-self: flex-end;
}

.feedback-input {
  border: 0.2rem solid #9452ff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  background-color: #e8dbfb;
  max-Height: 10rem;
}

.feedback-input::placeholder {
  color: #9452ff;
}

.feedback-input:focus {
  outline-color: #9452ff;
}

.feedback-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.feedback-btn {
  padding: 0.2rem 0.8rem;
  border-radius: 0.3rem;
  color: white;
  z-index: 1;
}

.feedback-btn-cancel {
  background-color: #40CFF9;
}

.feedback-btn-send {
  background-color: #9452FF;
}


.textarea:focus {
  outline: 1px solid #9452FF;
  border: 1px solid #9452FF;
  background-color: rgba(28, 30, 58, 1);

}

.txt p {
  font-size: 1.3rem;
  margin-top: 15px;
}

/*
Overlay styles
*/

.overlay-container2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(5px);
  overflow: hidden !important;
}

.overlay-info-container {
  position: relative;
  width: 40%;
  /* max-width: 530px; */
  max-width: 40rem;
  max-height: 52rem;
  overflow-y: scroll;
  height: auto;
  background: linear-gradient(#40CFF9, #9452FF);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-top: 3.5em;
  padding-bottom: 3.5em;
  overflow: hidden !important;
}

.overlay-info {
  width: 100%;
  height: auto;
}

.overlay-image-wrapper {
  height: 5rem;
  width: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 3.5rem;
}

.overlay-btn {
  background-color: #40CFF9;
  color: white;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 4rem;
  border-radius: 1rem;
  font-size: 1.75rem;
}

.overlay-btn:hover {
  transform: translateY(-5px);
}

.overlay-info h1 {
  font-size: 1.75rem;
  font-style: italic;
  font-weight: bold;
  line-height: 3rem;
  margin-bottom: 1.5rem;
  color: #f2f6f7;
}

.feature-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style-type: disc;
  padding-left: 1em;
}

.feature-list li::marker {
  color: white;
}

.feature-list li {
  font-size: 1.25rem;
  line-height: 2.75rem;

  /* Light mode */
  color: white;
}

.overlay-text-container {
  padding: 2rem;
  margin-bottom: 3rem;
}

.overlay-border {
  border: 1px solid #f2f6f7;
  ;
  border-radius: 0.5rem;
}

.overlay-logout-btn {
  /* Light mode */
  color: white;
}

.overlay-logout-btn:hover {
  color: #40CFF9 !important;
}

/* max-width: 1052px */
@media screen and (max-width: 66em) {
  .overlay-info-container {
    width: 50%;
  }
}

/* max-width: 280px */
@media screen and (max-width: 18em) {
  html {
    font-size: 70%;
  }

  .overlay-info-container {
    overflow-y: scroll;
  }

  .overlay-image-wrapper {
    margin-bottom: 2rem;
    height: 4rem;
    width: 4rem;
  }
}

/* max-width: 680px */
@media screen and (max-width: 43em) {

  .overlay-info-container {
    width: 100%;
    height: 100svh;
    padding-left: 2.5em;
    padding-right: 2.5em;
    border-radius: 0em;
  }

  .overlay-image-wrapper {
    margin-bottom: 2.5rem;
  }
}

/* max-width: 1024px */
@media screen and (max-width: 64em) {
  .overlay-info-container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .overlay-image-wrapper {
    height: 4rem;
    width: 4rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-top: 2.5rem;
  }

  .overlay-info-container {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  }

  .overlay-text-container {
    margin: 0rem;
  }
}



.as-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(5px);
  overflow: hidden !important;

}

.as-overlay-info-container {
  position: relative;
  width: 40%;
  /* max-width: 530px; */
  max-width: 40rem;
  max-height: 52rem;
  overflow-y: scroll;
  height: auto;
  background: linear-gradient(#40CFF9, #9452FF);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  overflow: hidden !important;

  h1 {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 3rem;
    margin-bottom: 1.5rem;
    color: #f2f6f7;
  }
}


.as-overlay-text-container {
  border: 1px solid #f2f6f7;
  border-radius: 0.5rem;
  width: 90%;
  max-height: 50rem;
  padding: 1rem 4rem;
  overflow-y: scroll;

}

.as-overlay-alternatives {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.2rem;
  ;
  background-color: #fff;
  border-radius: 0.7rem;
  cursor: pointer;

  .title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #9452FF;
  }

  .text {
    font-size: 0.85em;
    color: #727272;
  }
}

.as-selected {

  background-color: #d4fcb5;

  .title {
    color: #068f1f;
  }

  .text {
    color: #000;
  }
}

.as-overlay-alternatives:hover {
  background-color: #b68cf9;

  .title {
    color: #fff;
  }

  .text {
    color: #fff;
  }

}

.as-overlay-close {
  position: absolute;
  top: 2%;
  right: 1.5%;
  background: none;
  border: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.as-overlay-close:hover {
  font-size: 30px;
  scale: 1.2;
}

.search-container {
  margin-bottom: 1rem;
}

.search-input-wrapper {
  position: relative;
  margin-bottom: 0.5rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  padding-right: 2rem; /* Make room for the clear button */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.clear-search-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #999;
  cursor: pointer;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.clear-search-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

.search-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #40CFF9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #33a4c7;
}